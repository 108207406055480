<template>
  <div id="app">
    <!-- <router-view /> -->
    <BackendSwitcher v-if="$route.fullPath === '/config'" />
    <Map v-else />
  </div>
</template>

<script>
import BackendSwitcher from '@/components/BackendSwitcher'
import Map from '@/views/Map'
export default {
  name: 'App',
  components: {
    BackendSwitcher,
    Map
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
