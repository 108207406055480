import axios from 'axios'
let customAxios
if (document.env !== undefined && document.env.apiBackend !== undefined) {
  customAxios = axios.create({
    baseURL: document.env.apiBackend,
    withCredentials: true
  })
} else {
  if (process.env.VUE_APP_API_BACKEND === undefined) {
    throw new Error(
      `missing VUE_APP_API_BACKEND! create a ".env" file
      in project root with
      'VUE_APP_API_BACKEND = "https://your-api-url-target.whatever"'`
    )
  } else {
    if (process.env.VUE_APP_PROXY_API_BACKEND === 'true') {
      customAxios = axios
    } else {
      customAxios = axios.create({
        baseURL: localStorage.getItem('setApiBackend') ? localStorage.getItem('setApiBackend') : process.env.VUE_APP_API_BACKEND,
        withCredentials: true
      })
    }
  }
}
export default customAxios
