<template>
  <b-container class="mt-5 ml-0">
    <b-row
      class="justify-center mb-10"
    >
      <b-col
        class="mt-4"
        cols="10"
        xl="4"
        lg="4"
        md="5"
      >
        <h2>Default Backend:</h2>
        <b-button
          style="width: 100%; color: white"
          variant="success"
          @click="setDefaultApi"
        >
          {{ defaultApiBackend }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class=" my-5">
      <b-col
        cols="10"
        xl="4"
        lg="4"
        md="5"
      >
        <h2>Custom Api:</h2>
        <b-input
          v-model="selectedApiBackend"
          class="mb-2"
          placeholder="http://base.url.de"
          dense
          persistent-placeholder
          label="Custom Api:"
          @keyup.enter="updateApi"
        />
        <b-button
          style="width: 100%; color: white"
          variant="primary"
          :disabled="selectedApiBackend === null"
          @click="updateApi"
        >
          Set Api
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from '@/service/axios'
export default {
  data() {
    return {
      defaultApiBackend: '',
      selectedApiBackend: null
    }
  },
  computed: {},
  created() {
    this.defaultApiBackend = process.env.VUE_APP_API_BACKEND
    this.selectedApiBackend = localStorage.getItem('setApiBackend')
  },
  methods: {
    async updateApi() {
      axios.defaults.baseURL = this.selectedApiBackend
      await axios.get('api/ping', { params: { XDEBUG_SESSION_START: 'PHPSTORM' } })
        .then(res => {
          if (res.status === 200) {
            localStorage.setItem('setApiBackend', this.selectedApiBackend)
            this.$noty.success(`Backend auf ${this.selectedApiBackend} gesetzt`)
            this.$router.push('/')
          }
        })
        .catch(() => {
          this.$noty.error(`Backend ${this.selectedApiBackend} falsch`)
        })
    },
    setDefaultApi() {
      localStorage.removeItem('setApiBackend')
      this.selectedApiBackend = ''
      axios.defaults.baseURL = this.defaultApiBackend
      this.$noty.success('Backend auf Standard zurückgesetzt')
      this.$router.push('/')
    }
  }
}
</script>

<style lang="scss" scoped>
.full-page {
  width: 100%;
  height: 100%;
  }
</style>
