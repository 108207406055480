<template>
  <div id="sidebare">
    <b-sidebar
      v-model="isOpenSidebar"
      shadow
      no-header
      footer
    >
      <template #footer>
        <div class=" text-center align-items-center py-5">
          <a
            href="https://www.curasoft.de/"
            target="_blank"
          >
            <b-img
              class="logo-img "
              style="width: 200px;"
              :src="getLogo"
            />
          </a>
        </div>
        <div class=" px-5 mb-3 align-center d-flex justify-content-between">
          <a
            class="footer-content"
            href="https://www.curasoft.de/datenschutzerklaerung/"
            target="_blank"
          >
            Datenschutz</a>
          <a
            class="footer-content"
            href="https://www.curasoft.de/impressum/"
            target="_blank"
          >
            Impressum</a>
        </div>
      </template>
      <div class="d-flex header-section mt-3 mb-1 text-start">
        <b-icon
          icon="list"
          class="toggel-icon"
          style="width: 25px; height: 25px;"
          @click="isOpenSidebar = false"
        />
        <p class="h3">
          <strong class="maps-title">Maps</strong>
        </p>
      </div>
      <hr class="mb-3 mt-0">
      <div class="px-3">
        <div v-if="isRouts">
          <b-card class="card text-start">
            <p><strong>Touren</strong></p>
            <ul class="list-group text-start">
              <li
                v-for="(route, index) in routes"
                :key="index"
                disabled
                class="list-group-item mb-1 pb-0 cursor-default"
              >
                <input
                  id="checkbox"
                  v-model="route.show"
                  type="checkbox"
                  class="check-box"
                >
                <b-icon
                  class="mx-2 color-icon cursor-default"
                  style="width: 13px; height: 13px;"
                  icon="circle-fill"
                  text-color="green"
                  :style="getRouteColor(route)"
                />
                <button
                  class="location-button"
                  :disabled="!route.show"
                  @click="showRouteInfos(route)"
                >
                  {{ route.title }}
                </button>
                <ol
                  :style="!route.show ? 'color: #B2B3B4' : ''"
                  style="padding-left: 39px"
                >
                  <li
                    v-for="(station, stationIndex) in route.stations"
                    :key="stationIndex"
                  >
                    {{ station.title }}
                  </li>
                </ol>
              </li>
            </ul>
          </b-card>
        </div>
        <hr v-if="isPois & isRouts">
        <div v-if="isPois">
          <b-card class="card text-start">
            <p><strong>Orte</strong></p>
            <b-list-group class="d-flex">
              <div
                v-for="(poi, index) in pois"
                :key="index"
                class="d-flex  align-items-center"
                :style="poi.show ? 'cursor: pointer' : 'cursor: auto'"
              >
                <input
                  v-model="poi.show"
                  type="checkbox"
                  class="check-box"
                >
                <b-list-group-item
                  :disabled="!poi.show"
                  class="list-item d-flex justify-content-between"
                  @click="showPoiInfos(poi)"
                >
                  <div>
                    <button
                      class="mx-3 location-button"
                      :disabled="!poi.show"
                    >
                      {{ poi.title }}
                    </button>
                  </div>
                  <div>
                    <b-img
                      :class="!poi.show ? 'disable-poi' : ''"
                      blank-color="red"
                      style="width: 20px; color: red"
                      :src="getGeoImg"
                    />
                  </div>
                </b-list-group-item>
              </div>
            </b-list-group>
          </b-card>
        </div>
        <hr>
        <b-card
          v-if="!isPayloadEmpty"
          class="card-title text-start"
        >
          <p><strong>Info</strong></p>
          <b-card-text class="mb-1">
            <strong>{{ localRouteObject.title }}</strong>
          </b-card-text>
          <b-card-sub-title class="mb-2">
            {{ localRouteObject.street }}
          </b-card-sub-title>
          <b-card-sub-title class="mb-4">
            {{ localRouteObject.city }}
          </b-card-sub-title>
          <b-card-sub-title
            v-if="isDistanceAndTime"
            class="mb-2"
          >
            Fahrzeit: {{ localRouteObject.travel_time }}
          </b-card-sub-title>
          <b-card-sub-title
            v-if="isDistanceAndTime"
            class="mb-4"
          >
            Weg: {{ localRouteObject.distance }}
          </b-card-sub-title>
          <div v-if="localRouteObject.info && localRouteObject.info.length > 0">
            <div>
              <div
                v-for="(info1, index) in localRouteObject.info"
                :key="index"
                class="mb-2"
              >
                <b-card-sub-title>
                  {{ info1 }}
                </b-card-sub-title>
              </div>
            </div>
          </div>
        </b-card>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
export default {
  components: {},
  props: {
    payload: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isOpenSidebar: true,
      localRouteObject: this.payload
    }
  },
  computed: {
    ...mapGetters('Map', {
      getRoutes: 'getRoutes',
      getPOIs: 'getPOIs',
      getLogo: 'getLogo',
      getGeoImg: 'getGeoImg'
    }),
    routes: function() {
      return this.getRoutes
    },
    pois: function() {
      return this.getPOIs
    },
    isPoisCard: function() {
      return this.getPOIs.length > 1
    },
    isPayloadEmpty: function() {
      return (
        this.localRouteObject === undefined || this.localRouteObject === null
      )
    },
    isDistanceAndTime: function() {
      return this.localRouteObject.distance !== undefined
    },
    isPois: function() {
      return this.getPOIs.length > 0
    },
    isRouts: function() {
      return this.getRoutes.length > 0
    }
  },
  watch: {
    isOpenSidebar(val) {
      if (val === false) {
        this.$emit('closeSidebar')
      }
    }
  },
  created() {
    this.localRouteObject = this.payload
  },
  methods: {
    ...mapActions('Map', { changeMapCenter: 'changeMapCenter' }),
    ...mapMutations('Map', { setMapCenter: 'setMapCenter' }),
    closeSwitcherBox() {
      this.$emit('closeSidebar')
    },
    getRouteColor(route) {
      return route.show ? `color: ${route.color}` : 'color: #B2B3B4'
    },
    showRouteInfos(route) {
      this.localRouteObject = route
      this.setMapCenter(route.center)
    },
    showPoiInfos(poi) {
      this.localRouteObject = poi
      this.setMapCenter(poi)
    }
  }
}
</script>
<style lang="scss" scoped>
.header-section {
  top: 2%;
}
.b-sidebar {
  width: 50px !important;
}
#sidebare {
  max-width: 50px !important;
}
.color-icon {
  margin-bottom: 2px;
}
.list-group-item {
  border: 0 none;
  cursor: pointer;
  padding-left: 0px;
  background-color: rgb(248, 249, 250);
}
.card {
  border: 0 none;
  padding-top: 0px !important;
  top: 0%;
  background-color: rgb(248, 249, 250);
}
.card-body {
  padding-top: 0px;
}
.toggel-icon {
  cursor: pointer;
  margin-left: 11px;
  margin-top: 5px;
}
.maps-title {
  font-size: 1.8rem;
  font-family: "Roboto", sans-serif;
}
.bi-list {
  margin-right: 9px;
}
.label {
  cursor: pointer;
}
.check-box {
  cursor: pointer;
}
.location-button {
  border: none;
  background: none;
}
.location-button:disabled {
  cursor: default;
}
.cursor-default {
  cursor: default;
}
.list-item {
  width: 100%;
}

.footer-content {
  font-size: 16px;
  color: #b2b3b4 !important;
  text-decoration: none;
}
.disable-poi{
  filter: opacity(50%);
}
</style>
