<template>
  <div id="map">
    <!-- v-if="isOpenInfoSidebar" -->
    <b-button
      v-b-tooltip.hover.left
      title="Route Informationen einblenden"
      variant="primary"
      class="routes-button"
      @click="setClickedInfos(null, null, '')"
    >
      <b-icon
        icon="list"
        class="toggel-icon"
        style="width: 25px; height: 25px;"
      />
    </b-button>
    <l-map
      :bounds="mapData.bounds"
      :zoom="mapData.zoom"
      :center="mapData.center"
      :options="{ zoomControl: false }"
      @click="isClicked = false"
      @update:center="currentCenterUpdate"
    >
      <l-control-zoom position="bottomright" />
      <l-tile-layer :url="mapData.url" />
      <div
        v-for="(mainRoute, routeIndex) in routes"
        :key="routeIndex + 'A'"
      >
        <div
          v-for="(route1, wayIndex) in mainRoute.route"
          :key="wayIndex + 'B'"
        >
          <l-polyline
            v-if="mainRoute.show"
            :lat-lngs="route1"
            :weight="8"
            :color="mainRoute.color"
            @click="setClickedInfos(routeIndex, null, 'polyline')"
          />
          <l-polyline
            v-if="mainRoute.show"
            :lat-lngs="route1"
            :weight="4"
            :color="lightenColor(mainRoute.color, 25)"
            @click="setClickedInfos(routeIndex, null, 'polyline')"
          >
            <l-tooltip
              class="polyline-tooltip"
              :options="{ sticky: true }"
            >
              <span>Fahrzeit: {{ mainRoute.travel_time }}</span>
              <span> Weg: {{ mainRoute.distance }}</span>
            </l-tooltip>
          </l-polyline>
        </div>
        <div
          v-for="(station, stationIndex) in mainRoute.stations"
          :key="stationIndex"
        >
          <l-marker
            v-if="mainRoute.show"
            :lat-lng="getLatLong(station.location)"
            @click="setClickedInfos(stationIndex, routeIndex, 'station')"
          >
            <l-tooltip> {{ station.title }} </l-tooltip>
            <l-icon
              :icon-url="station.icon"
              :icon-size="station.iconSize"
            />
          </l-marker>
          <l-marker
            v-if="mainRoute.show"
            :lat-lng="getLatLong(station.location)"
            @click="setClickedInfos(stationIndex, routeIndex, 'station')"
          >
            <l-tooltip> {{ station.title }} </l-tooltip>
            <l-icon
              :icon-url="station.icon"
              :icon-size="station.iconSize"
            >
              <span
                style="font-size: 13px;position:absolute;text-align:center;width:26px;padding-top:3px;"
              ><strong>{{ stationIndex + 1 }}</strong></span>
            </l-icon>
          </l-marker>

          <l-marker
            v-if="mainRoute.show && station.icon2"
            :lat-lng="getLatLong(station.location)"
            @click="setClickedInfos(stationIndex, routeIndex, 'station')"
          >
            <l-tooltip> {{ station.title }} </l-tooltip>
            <l-icon
              :icon-url="station.icon2"
              :icon-size="station.iconSize2"
              :icon-anchor="[11, 22]"
              :popup-anchor="[0, -51]"
            />
          </l-marker>
        </div>
      </div>
      <div
        v-for="(poi, poiIndex) in pois"
        :key="'C' + poiIndex"
      >
        <l-marker
          v-if="poi.show"
          :lat-lng="getLatLong(poi.location)"
          @click="setClickedInfos(poiIndex, null, 'poi')"
        >
          <l-tooltip> {{ poi.title }} </l-tooltip>
          <l-icon
            :icon-url="poi.icon"
            :icon-size="poi.iconSize"
            color="green"
          />
        </l-marker>
      </div>
    </l-map>
    <InfoSidebar
      v-if="isOpenInfoSidebar || isPointClicked"
      id="info-sidebar"
      :key="reload"
      :payload="getClickedInfo()"
      @closeSidebar="closeSidebar"
    />
  </div>
</template>

<script>
import {
  LMap,
  LTileLayer,
  LPolyline,
  LMarker,
  LIcon,
  LTooltip,
  LControlZoom
} from 'vue2-leaflet'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import axios from '@/service/axios'
import InfoSidebar from '@/components/InfoSidebar'

export default {
  components: {
    LMap,
    LTileLayer,
    LPolyline,
    LMarker,
    LIcon,
    LTooltip,
    LControlZoom,
    InfoSidebar
  },
  data() {
    return {
      clickedIndex: 0,
      clickedRouteIndex: 0,
      clickedType: '',
      isPointClicked: false,
      reload: 1,
      token: '',
      backendUrl: '',
      pois: []
    }
  },
  computed: {
    ...mapGetters('Map', {
      mapData: 'mapData',
      poiData: 'getPOIData',
      routeData: 'getRouteData',
      stationData: 'getStationData',
      getRoutes: 'getRoutes',
      getPOIs: 'getPOIs',
      getStationIcon: 'getStationIcon',
      getPoiIcon: 'getPoiIcon'
    }),
    routes: function() {
      return this.getRoutes
    },
    POIs: function() {
      return this.getPOIs
    },
    isOpenInfoSidebar: function() {
      return this.mapData.stations.length > 0 || this.mapData.pois.length > 0
    }
  },
  async created() {
    this.token = this.$route.path.substring(1)
    this.backendUrl = this.$route.fullPath.substring(1)
    if (this.backendUrl.includes('backend')) {
      this.backendUrl = decodeURIComponent(this.backendUrl)
      const backend = this.backendUrl.substr(
        this.backendUrl.indexOf('=') + 1,
        this.backendUrl.length
      )
      axios.defaults.baseURL = backend
      localStorage.setItem('setApiBackend', backend)
    }
    await this.fetchMapData(this.token)
    this.delayPois()
  },
  methods: {
    ...mapActions('Map', {
      fetchMapData: 'fetchMapData',
      getFormattedTime: 'getFormattedTime'
    }),
    ...mapMutations('Map', {
      updateClickedLatLng: 'updateClickedLatLng',
      updateZoom: 'updateZoom',
      updateBounds: 'updateBounds',
      setMapCenter: 'setMapCenter',
      setCustomBackend: 'setCustomBackend'
    }),
    lightenColor(color, percent) {
      var num = parseInt(color.replace('#', ''), 16)
      var amt = Math.round(2.55 * percent)
      var R = (num >> 16) + amt
      var B = ((num >> 8) & 0x00ff) + amt
      var G = (num & 0x0000ff) + amt
      return (
        '#' +
        (
          0x1000000 +
          (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
          (B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
          (G < 255 ? (G < 1 ? 0 : G) : 255)
        )
          .toString(16)
          .slice(1)
      )
    },
    delayPois() {
      setTimeout(() => {
        this.pois = this.getPOIs
      }, 100)
    },
    setClickedInfos(index, routeIndex, clicked) {
      this.clickedIndex = index
      this.clickedRouteIndex = routeIndex
      this.clickedType = clicked
      this.isPointClicked = true
      this.reload++
    },
    getLatLong(data) {
      return [data.latitude, data.longitude]
    },
    getClickedInfo() {
      if (this.clickedType === 'poi') return this.poiData(this.clickedIndex)
      if (this.clickedType === 'polyline') { return this.routeData(this.clickedIndex) }
      if (this.clickedType === 'station') {
        return this.getRoutes[this.clickedRouteIndex].stations[
          this.clickedIndex
        ]
      }
    },
    closeSidebar() {
      this.isPointClicked = false
    },
    showRoute(isShowRout) {
      return isShowRout
    },
    currentCenterUpdate(center) {
      const currentCenter = {
        location: {
          latitude: center.lat,
          longitude: center.lng
        }
      }
      this.setMapCenter(currentCenter)
    }
  }
}
</script>
<style lang="scss" scoped>
#map {
  position: relative;
  height: 100vh;
  width: 100vw;
  z-index: 0;

  .show-routs-card {
    position: absolute;
    right: 0;
    top: 0;
    size: 130px;
  }
  .routes-button {
    position: absolute;
    left: 0.5rem;
    z-index: 1000;
    padding: 0.1em;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 1.9%;
    transition: 2s;
    background: white;
    color: black;
    border: 2px solid #a7a3a3;
  }
}
.zoom-control {
  display: none !important;
}
#info-sidebar {
  width: 50px !important;
}
.polyline-tooltip {
  display: flex;
  flex-direction: column;
  text-align: left;
}
</style>
